import React, {lazy, Suspense} from "react";
const HeadPage = lazy(() => import("../components/HeadPage"));
const ProductPage = lazy(() => import("../components/ProductPage"));
const AboutUs = lazy(() => import("../components/AboutUs"));
const WorkExamples = lazy(() => import("../components/WorkExamples"));
const Reviews = lazy(() => import("../components/Reviews"));
const Quality = lazy(() => import("../components/Quality"));
const FuelBlocks = lazy(() => import("../components/FuelBlocks"));
const BeautifulAndSafe = lazy(() => import("../components/BeautifulAndSafe"));
const Delivery = lazy(() => import("../components/Delivery"));
const PaymentAndDelivery = lazy(() => import("../components/PaymentAndDelivery"));
const FireplaceWarranty = lazy(() => import("../components/FireplaceWarranty"));
const Designers = lazy(() => import("../components/Designers"));
const Answers = lazy(() => import("../components/Answers"));
const Footer = lazy(() => import("../components/Footer"));

const TitlePage = () => {
  return (
    <div>
          <HeadPage />
       <Suspense fallback={<div>Loading...</div>}>
          <ProductPage />
          <AboutUs />
          <WorkExamples />
          <Reviews />
          <Quality />
          <FuelBlocks />
          <BeautifulAndSafe />
          <Delivery />
          <PaymentAndDelivery />
          <FireplaceWarranty />
          <Designers />
          <Answers />
          <Footer />
      </Suspense>
    </div>
  );
};
export default TitlePage;
